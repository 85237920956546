import React from "react";
import "./Contact.css";
import { useState } from "react";

function Contact() {
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [status, setStatus] = useState("unsent");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("sending");

    const formData = new FormData(e.target);
    formData.append("access_key", "f0c1f978-454b-4017-9967-c378fdc06141");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });
      setStatus("sent");
    } catch (e) {
      setStatus("error");
    }
  };

  return (
    <div className="section__contact section">
      <h1>Contact Me</h1>

      {status === "unsent" && (
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            onChange={handleChange}
            value={data.name}
            required
          />

          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            onChange={handleChange}
            value={data.email}
            required
          />

          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            onChange={handleChange}
            value={data.message}
            required
          />

          <button type="submit">Submit</button>
        </form>
      )}

      {status === "sending" && (
        <p>sending... (this should only take a moment)</p>
      )}
      {status === "sent" && (
        <p>
          Your message has been sent! I'll get back to you as quickly as
          possible
        </p>
      )}
      {status === "error" && <p>Error occurred. Please try again later.</p>}
    </div>
  );
}

export default Contact;
