import React from "react";
import "./About.css";
function About() {
  return (
    <div className="section section__about" id="about-anchor">
      <h1>About</h1>
      <p>
        Hey there! I'm a 19-year-old based in Hamburg, Germany, with a passion
        for backend development and a growing interest in frontend development.
        <br />
        <br />
        In addition to my coding pursuits, I also manage a server at home where
        I host private services and public instances, (privacy.com.de).
        <br />
        <br />
        My journey into the world of technology began at a young age when my
        parents introduced me to a laptop at the age of 5. This early exposure
        sparked my fascination with computers, leading me down the path of
        software development and linux adminstration. Three years ago, I made
        the switch to using Linux as my daily driver, diving deeper into the
        world of open-source software and customization. This decision has not
        only shaped my technical skills but has also influenced my approach to
        problem-solving and innovation in the digital realm.
        <br />
        <br />I am constantly seeking new challenges and opportunities to expand
        my knowledge and skills in the ever-evolving field of technology. Feel
        free to reach out and connect with me on my journey of learning and
        growth in the world of software development!
      </p>
    </div>
  );
}

export default About;
