import "./ProjectCard.css";
function ProjectCard({ name, description, image, link }) {
  return (
    <div className="project-card">
      <h1>{name}</h1>
      <p>{description}</p>
      <a href={link}>Project Page</a>
    </div>
  );
}

export default ProjectCard;
