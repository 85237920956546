import React from "react";
import "./Home.css";
import "animate.css";
import Greeting from "./Greeting";
import ProjectCard from "./ProjectCard";

function Home() {
  return (
    <div id="home-container">
      <Greeting />
      <div className="section section__projects">
        <h1>Projects</h1>
        <ProjectCard
          link="https://google.de"
          name="Posts."
          description="A privacy-focused micro-blogging app designed for meaningful connections with your inner circle. Say goodbye to the noise of traditional social networks; 'Posts' allows you to share thoughts, updates, and moments exclusively with your chosen friends in personalized circles. Experience a new way of socializing where your feed is filled only with updates from those you truly care about. Connect, share, and engage in a safe and intimate online space with 'Posts'."></ProjectCard>
        <ProjectCard
          link="https://privacy.com.de/"
          name="SeatMe"
          description="'Seat-Me' is an AI-powered seat plan generator app tailored for schools. By harnessing advanced algorithms, 'Seat-Me' creates the optimal seating arrangement based on your unique constraints and preferences. Whether it's grouping students, maximizing engagement, or accommodating special needs, 'Seat-Me' creates the perfect seating plan. The development is currently paused."></ProjectCard>
        <ProjectCard
          link="https://privacy.com.de/"
          name="Privacy.com.de"
          description="Privacy.com.de is all about providing free, privacy-focused services to everyone. 
          The platform gives individuals access to privacy-freindly instances of open-source projects. The main goal is to protect user data and advocate for digital anonymity. 
          Based on a Linux server in my home, Privacy.com.de ensures strong data protection and confidentiality. This decentralized setup reflects the commitment to safeguarding user privacy."></ProjectCard>
      </div>
    </div>
  );
}

export default Home;
