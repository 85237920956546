import React, { useState, useEffect } from "react";
import TypingAnimation from "../../TypingAnimation";
import "./Home.css";

const roles = [
  "Backend Developer",
  "Linux Admin",
  "Privacy Enthusiast",
  "Open Source Contributor",
];

function Greeting() {
  const [currentRoleIndex, setCurrentRoleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentRoleIndex((prevIndex) => (prevIndex + 1) % roles.length);
    }, 3500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="animate__animated animate__fadeIn">
      <div className="wave-animation">👋</div>
      <h1>
        Hey. My name is <span className="colored-text">Till Großmann</span>.
      </h1>
      <h1>

<div id="animated-text">
<span className="colored-background">
          <TypingAnimation text={roles[currentRoleIndex]} typingSpeed={100} />
        </span>
</div>

        
      </h1>
    </div>
  );
}

export default Greeting;

